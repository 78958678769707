<template>
    <div v-if="type == 'inline'">
        <div v-for="phone in phones" :key="phone.phone_id">
            <div class="row mt-4">
                <div class="col-auto">
                    <p><e-copybox phone classes="mb-3" :label="phone.phone_type" :value="phone.phone_combine"></e-copybox>
                        <span v-if="phone.phone_libelle && phone.phone_libelle != ''"> ({{ phone.phone_libelle }})</span>
                    </p>
                </div>					
            </div>							
        </div>							
    </div>
    <span v-else-if="type == 'picto'">
        <span v-for="phone in phones" :key="phone.phone_id" class="mx-2">
            <i v-b-tooltip.left :title="phone.phone_combine" v-if="phone.phone_type == $t('tiers.phone_type.portable')">
                <font-awesome-icon :icon="['fal', 'mobile']"/>
            </i>
            <i v-b-tooltip.left :title="phone.phone_combine" v-else-if="phone.phone_type == $t('tiers.phone_type.fixe')">
                <font-awesome-icon :icon="['fal', 'phone']"/>
            </i>
        </span>
    </span>
</template>

<script type="text/javascript">
import Tools from '@/mixins/Tools.js'
import Phone from "@/mixins/Phone.js"

export default {
	name: 'coordonneesTiers',
	mixins: [Tools, Phone],
	components: {

	},
    props: {
        tiers_id: { type: Number, default: 0},
        contact_id: { type: Number, default: 0},
        type: { type: String, default: 'inline'},
    },
	data() {
		return {
			phones : {}
		}
	},

	/* Initialisation du tableau */
	mounted: function() {
       this.initComponent()
	},

	methods: {
        async initComponent(){
        	if(this.tiers_id != 0)
        	{
				this.phones = await this.loadPhoneTiers(this.tiers_id);
        	}

        	if(this.contact_id != 0)
        	{
        		this.phones = await this.loadPhoneContact(this.contact_id);
        	}
        }
    },

    computed: {
        
    },
    watch: {
        
    }
}
</script>
